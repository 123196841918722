<template>
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="#F7D41E"
    xmlns="http://www.w3.org/2000/svg"
    :class="[customClass.toString()]"
  >
    <path
      d="M11.0691 17.3384L16.1301 20.3994C17.0569 20.9604 18.1911 20.1311 17.9471 19.0823L16.6057 13.3262L21.0813 9.44818C21.8984 8.74086 21.4593 7.3994 20.3862 7.31403L14.4959 6.81403L12.1911 1.37501C11.7764 0.387204 10.3618 0.387204 9.94715 1.37501L7.64227 6.80184L1.75203 7.30184C0.678859 7.3872 0.239835 8.72867 1.05691 9.43599L5.53252 13.314L4.19105 19.0701C3.94715 20.1189 5.0813 20.9482 6.00813 20.3872L11.0691 17.3384Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconStar",
  components: {},
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
};
</script>
